// Namespacing

window.Bean = window.Bean || {};

// Launch modules

(function ($) {

  'use strict';

  var app = this;

  $(document).ready(function () {

    app.animation.init();
    app.validation.init();
    app.dataForm.init();
    app.campaign.init();
  });

  flexibility(document.documentElement);

}.bind(Bean, jQuery)());

function initAutocomplete () {

  Bean.location.init();
}