
$(function() {

    $( '.change-lang-form' ).change(function() {

        var lang = $(this).val();

        location.href = PHP_LANG + '/' + lang;

    });

});