
$(function() {

    if ( $( '#changeDelivery' ).length > 0 ) {

        $( '#changeDelivery' ).change(function() {

            var value = $( this ).val(),
                url = PHP_CHANGE_TAXES + '/' + value;

            $.ajax({
                    url: url
                })
                .done(function( data ) {

                    var taxes = parseFloat( data['taxes'] ),
                        name = data['name'],
                        price = parseFloat( $( '#init_price').html() ),
                        _name = $( '#taxes_name' ),
                        _taxes = $( '#taxes_amount'),
                        _total = $( '#taxes_total'),
                        _stripe = $( '#stripe_amount'),
                        totalTaxes = parseFloat( ( price * taxes ) / 100).toFixed(2),
                        bigTotal = parseFloat( price + parseFloat( totalTaxes ) );

                    _name.html( name );
                    _taxes.html( totalTaxes );
                    _total.html( bigTotal );
                    _stripe.val( parseInt( bigTotal * 100 ) );

                });

        });

    }

    if ( $( "#stripePayment").length > 0 ) {

        $( '.input-edit-group' ).on('classChange', function() {

            if ( $( this ).hasClass( 'disabled' ) ) {

                $( "#stripePayment").removeAttr( 'disabled' );

            } else {

                $( "#stripePayment").attr('disabled', true);

            }

        });

    }

});