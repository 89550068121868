
$(function() {

    if ( $('#stripePayment').length > 0 ) {

        var handler = StripeCheckout.configure({
            key: STRIPE_KEY,
            image: 'https://s3.amazonaws.com/stripe-uploads/acct_17etgvKp9KRvZVYHmerchant-icon-1455571163216-192x192.png',
            locale: 'auto',
            token: function(token) {
                // Use the token to create the charge with a server-side script.
                // You can access the token ID with `token.id`

                var _form = $( '#order-charge-form' );

                $( '<input/>' )
                    .attr('type', 'hidden')
                    .attr('name', 'stripeToken')
                    .val( token.id )
                    .appendTo( _form );

                _form.submit();
            }
        });

        $('#stripePayment').on('click', function(e) {
            // Open Checkout with further options
            handler.open({
                name: 'Bean',
                email: $( '#stripe_email').val(),
                description: $( '#stripe_description').val(),
                currency: "cad",
                amount: $( '#stripe_amount').val(),
                locale: $( '#stripe_locale').val()
            });
            e.preventDefault();
        });

        // Close Checkout on page navigation
        $(window).on('popstate', function() {
            handler.close();
        });

    }

});