
$(function() {


    if(typeof HOMEPAGE != 'undefined' && HOMEPAGE) {

        var slider = false,
            mySwiper = undefined;

        resizeScreen();

        $( window ).resize(function() {

            resizeScreen();

        });


    }

    function resizeScreen() {

        var screenWidth = $( window ).width();

        var space_left = 20;
        if (screenWidth >= 768 && screenWidth < 1024) {

            space_left = 30;
        }

        if (screenWidth < 768 && slider != 2) {

            if (slider == 1) {

                destroySwiper();

            }

            mySwiper = new Swiper('.swiper-container', {
                pagination: '.swiper-pagination',
                slidesPerView: 1.2,
                paginationClickable: true,
                slidesOffsetBefore: space_left,
                slidesOffsetAfter: space_left,
                spaceBetween: space_left
            });

            slider = 2;

        } else if (screenWidth >= 768 && screenWidth < 1024 && slider != 1) {

            if (slider == 2) {

                destroySwiper();

            }

            mySwiper = new Swiper('.swiper-container', {
                pagination: '.swiper-pagination',
                slidesPerView: 2.2,
                paginationClickable: true,
                slidesOffsetBefore: space_left,
                slidesOffsetAfter: space_left,
                spaceBetween: space_left
            });

            slider = 1;

        } else if (screenWidth >= 1024 && slider) {

            destroySwiper();

            slider = false;

        }

    }

    function destroySwiper() {

        $('.swiper-container').each(function() {

            var swiper = $(this)[0].swiper;

            swiper.destroy(true, true);

        });

    }

});