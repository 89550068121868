
// Namespacing

window.Bean = window.Bean  || {};


// Animation Module

(function ($) {

    "use strict";

    var self;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function Animation() {

        self = this;
    }

    // ------------------------------------
    // METHODS
    // ------------------------------------

    Animation.prototype.init = function () {
        
        this.toggleNavigationHandler();
        this.toggleMobileNavigationHandler();
    };


    Animation.prototype.toggleNavigation = function (button) {

        self = this;

        $('#navigation-toggle').toggleClass('open');
        $('#navigation').toggleClass('visible');
    };


    Animation.prototype.toggleMobileNavigation = function (button) {

        self = this;

        $('#mobile-nav').toggleClass('open');
    };



    // ------------------------------------
    // HANDLERS
    // ------------------------------------

    Animation.prototype.toggleNavigationHandler = function () {

        self = this;

        $('#navigation-toggle').click( function(e) {

            self.toggleNavigation( $(this) );

            e.preventDefault();
            return false;

        });
    };


    Animation.prototype.toggleMobileNavigationHandler = function () {

        self = this;

        $('#open-mobile-nav, #close-mobile-nav').click( function(e) {

            self.toggleMobileNavigation( $(this) );

            e.preventDefault();
            return false;

        });
    };



    this.animation = new Animation();


}.bind(Bean, jQuery)());