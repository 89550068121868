
// Namespacing

window.Bean = window.Bean  || {};


// Campaign Module

(function ($) {

    "use strict";

    var self,
        $picture,
        $iframe,
        $deletePicture,
        $deleteVideo;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function Campaign() {

        self = this,
        $picture = $('#image-preview'),
        $iframe = $('#video-preview'),
        $deletePicture = $('#delete-picture'),
        $deleteVideo = $('#delete-video');
    }

    // ------------------------------------
    // METHODS
    // ------------------------------------

    Campaign.prototype.init = function () {

        this.imageUpload();
        this.videoUpload();
        this.removeImage();
        this.removeVideo();
        this.goToSummary();

        // Initialize tinyMCE

        if( $('.wysiwyg').length ) {

            tinymce.init({
                selector: '.wysiwyg',
                height: 420,
                menubar: false,
                toolbar: 'styleselect | bold italic underline | alignleft aligncenter alignright | bullist | link media image',
                content_css : "/custom/custom_tinymce.css",
                fontsize_formats: '16px',
                font_formats: 'Oxygen=Oxygen,Helvetica,Arial',
                style_formats: [
                    { title: 'Title 1', block: 'h2' },
                    { title: 'Title 2', block: 'h3' },
                    { title: 'Text', block: 'p' }
                ],
                plugins: [
                    'advlist autolink lists link image preview anchor',
                    'fullscreen',
                    'media paste code'
                ]
            });
        }

    };


    Campaign.prototype.imageUpload = function () {

        $('#picture-field').change(function () {

            $picture.addClass('loading').closest('.group_preview').removeClass('error');

            if (this.files && this.files[0]) {

                if ( this.files[0].type == 'image/png' || this.files[0].type == 'image/jpeg' ) {

                    var reader = new FileReader();

                    reader.onload = function(e) {

                        $.post('/campaigns/' + campaign_id + '/picture',
                            {
                                'picture' : e.target.result
                            },
                            function(data, status){

                                if( status == 'success' ) {

                                    $picture.css('background-image', 'url(' + data.picture + ')');
                                }
                                else {

                                    alert('Error');
                                }

                                $picture.removeClass('loading');
                                $picture.addClass('active');
                                $picture.parent().parent().addClass('hasPicture');
                                $deletePicture.show();
                            });
                    };

                    reader.readAsDataURL(this.files[0]);
                } else {

                    $picture.removeClass('loading').closest('.group_preview').addClass('error');
                }
            }
        });
    };


    Campaign.prototype.videoUpload = function () {

        $('#video-field').change(function () {

            var value = $(this).val(),
                url;

            $iframe.addClass('loading');

            // Is the video from Youtube or Vimeo

            if( value.search('youtu.be/') != -1 || value.search('youtube.com/') != -1 ) {

                var video_id = self.youtubeParser( value );

                var url = 'https://www.youtube.com/embed/' + video_id;
            }
            else if( value.search('vimeo.com/') != -1 && value.search('player.vimeo.com/') == -1 ) {

                var video_id = self.vimeoParser( value );

                var url = 'https://player.vimeo.com/video/' + video_id;
            }
            else {

                var url = value;
            }


            // Save the video

            $.post('/campaigns/' + campaign_id + '/video',
            {
                'video' : url
            },
            function(data, status){

                if( status == 'success' ) {

                    $iframe.attr('src', data.video);
                    $iframe.addClass('active');
                    $iframe.parent().parent().addClass('hasVideo');
                }  
                else {

                    alert('Error');
                }   
            });
        });


        $iframe.on('load', function(event){

            $(this).removeClass('loading');

        });
    };


    Campaign.prototype.youtubeParser = function (url) {

        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = url.match(regExp);
        return (match&&match[7].length==11)? match[7] : false;
    };


    Campaign.prototype.vimeoParser = function (url) {

        var regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
        var match = url.match(regExp);
        return (match&&match[5].length==9)? match[5] : false;
    };


    Campaign.prototype.removeImage = function () {

        $deletePicture.click(function (e) {

            //$picture.addClass('loading');

            $.ajax({
                url: '/campaigns/' + campaign_id + '/picture',
                type: 'DELETE',
                success: function(data, status) {
    
                    if( status == 'success' ) {

                        $picture.css('background-image', 'none');
                    }  
                    else {

                        alert('Error');
                    }    

                    //$picture.removeClass('loading');
                    $picture.removeClass('active');
                    $picture.parent().parent().removeClass('hasPicture');
                    $deletePicture.hide();
                    $('#picture-field').val('');
                }
            });

            e.preventDefault();
            return false;
        });
    };


    Campaign.prototype.removeVideo = function () {

        $deleteVideo.click(function (e) {

            //$iframe.addClass('loading');

            $.ajax({
                url: '/campaigns/' + campaign_id + '/video',
                type: 'DELETE',
                success: function(data, status) {
    
                    if( status == 'success' ) {

                        $iframe.attr('src', null);
                    }
                    else {

                        alert('Error');
                    }   

                    //$iframe.removeClass('loading');
                    $iframe.removeClass('active');
                    $iframe.parent().parent().removeClass('hasVideo');
                    $('#video-field').val('');
                }
            });

            e.preventDefault();
            return false;
        });
    };


    Campaign.prototype.goToSummary = function () {

        $('#finish-input').click(function (e) {

            var form = $(this).parent().parent();

            $(form).find('#finish-field').val(1);

            $(form).submit();

            e.preventDefault();
            return false;
        });
    };



    this.campaign = new Campaign();


}.bind(Bean, jQuery)());