//
// $(function() {
//
//
//     if(typeof HOMEPAGE != 'undefined' && HOMEPAGE) {
//
//         var current = 0;
//
//         resizeCTA();
//
//         $( window ).resize(function() {
//
//             resizeCTA();
//
//         });
//
//     }
//
//     function resizeCTA() {
//
//         var width = $(window).width(),
//             height = $(window).height();
//
//         //phase_0 = 820 <-> ??? : normal
//         //phase_1 = 750 <-> 820 : -50px
//         //phase_2 = 0 <-> 750 : -100px
//
//         if ( width > 1024 ) {
//
//             if ( height <= 750 && current != 2 ) {
//
//                 $( '.home-full').css('padding-top', '40px').css('padding-bottom', '40px');
//
//                 current = 2;
//
//             } else if ( height > 750 && height <= 820 && current != 1 ) {
//
//                 $( '.home-full').css('padding-top', '150px').css('padding-bottom', '150px');
//
//                 current = 1;
//
//             } else if ( height > 820 && current != 0 ) {
//
//                 $( '.home-full').css('padding-top', '200px').css('padding-bottom', '200px');
//
//                 current = 0;
//
//             }
//
//         }
//
//     }
//
// });