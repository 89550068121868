
$(function() {

    if ( $( '#randomize').length > 0 ) {

        $('body').on('click', '#randomize', function() {

            var url = PHP_RANDOMIZE,
                _content = $( '.favorite-content' );

            _content.css('height', _content.height() + 'px');
            _content.find('.big').fadeOut();

            $.ajax({
                    url: url
                })
                .done(function( data ) {

                    _content.empty().html( data ).css('height', 'auto');

                    var height = _content.height();

                    _content.find('.big').css('display', 'none');

                    _content.css('height', height);
                    _content.find('.big').fadeIn();

                });

        });

    }

});