
$(function() {

    if ( $( '.steps' ).length > 0 ) {

        $('.step:not(.active):not(.locked)').click(function () {

            if ( $(this).data('next') )  {

                $('#next-field').val( $(this).data('next') );
                $('form').submit();
            } else {

                location.href = $(this).data('href');
            }
        });


        $('#go_prev').click(function() {

            $('.step.active').prev().click();

        });

    }

});