
$(function() {

    if ( $( '#explore_category' ).length > 0 ) {

        $( '#explore_category, #explore_filter').change( function() {

            $( this ).closest( 'form' ).submit();
        });
    }

});