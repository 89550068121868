
$(function() {

    $('a.confirm').click(function(e) {

        if ( $(this).hasClass('confirmed') ) {

        } else {

            e.preventDefault();
            $(this).addClass('confirmed');
        }

    });

});