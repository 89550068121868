
// Namespacing

window.Bean = window.Bean  || {};


// Location Module

(function ($) {

    "use strict";

    var self,
        placeSearch, 
        autocomplete,
        componentForm;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function Location() {

        self = this;
        this.componentForm = {

          street_number: 'short_name',
          route: 'long_name',
          locality: 'long_name',
          administrative_area_level_1: 'short_name',
          country: 'long_name',
          postal_code: 'short_name'
        };
    }

    // ------------------------------------
    // METHODS
    // ------------------------------------

    Location.prototype.init = function () {
        
        this.autocomplete();
    };


    Location.prototype.autocomplete = function () {

        self = this;

        // Create the autocomplete object, restricting the search to geographical
        // location types.
        
        if( document.getElementById('autocomplete') ) {

            autocomplete = new google.maps.places.Autocomplete(
            
                /** @type {!HTMLInputElement} */

                (document.getElementById('autocomplete')), {types: ['geocode']}

            );
        }
    };


    this.location = new Location();


}.bind(Bean, jQuery)());