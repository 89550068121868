$(function () {

  if ($('#order-create-form').length > 0) {

    var _quantity = $('#quantity'),
      _donation = $('#select_donation'),
      _subTotal = $('#subtotal'),
      bundle_price = parseInt( _subTotal.text() ),
      _bigTotal = $('#taxes_total'),
      _donation_text = $('#donation_amount');

    _donation.change(function () {

      updatePrice();
    });

    _quantity.change(function () {

      var quantity = $('#quantity').val(),
        subtotal = parseInt( quantity * bundle_price );

      _subTotal.text( subtotal );

      updatePrice();
    });

  }

  function updatePrice () {

    var quantity = _quantity.val(),
      donation_value = _donation.val(),
      bundle_donation = $('#donation_amount').data('donation');

    var bigTotal = parseInt( (bundle_price * quantity) + +donation_value );
    var donationAmount = parseInt( (bundle_donation * quantity) + +donation_value );

    _donation_text.text( donationAmount );

    _bigTotal.text(bigTotal);
  }

});