
$(function() {

    if ( $('.sticky').length > 0 ) {

        $( window ).scroll(function(){

            $( '.sticky' ).each(function() {

                var _sticky = $( this ),
                    thisTop = parseInt($( window ).scrollTop() + _sticky.position()['top']),
                    _anchor = _sticky.next(),
                    toTop = $( window ).scrollTop(),
                    stickyTop = 0;

                if ( $('.sticky.fixed').length > 0 ) {

                    $('.sticky.fixed').each(function() {

                        toTop += $( this ).outerHeight();
                        stickyTop += $( this ).outerHeight();

                    });

                }

                if ( _sticky.data('height') ) {

                    var stickyHeight = _sticky.data('height');

                } else {

                    var stickyHeight = _sticky.outerHeight();

                }

                if ( !_sticky.hasClass('fixed') && toTop > _sticky.position()['top'] ){

                    if ( !( _sticky.hasClass('not_mobile') && $( window ).width() < 480 ) ) {

                        _sticky.addClass('fixed').css('top', stickyTop).next()
                            .css('padding-top', stickyHeight + 'px');

                    }

                }

                if ( _sticky.hasClass('fixed') && thisTop < _anchor.position()['top'] ){

                    _sticky.removeClass('fixed').next()
                        .css('padding-top','0');
                }

            });

        });

    }

});