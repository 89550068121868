
$(function() {


    if(typeof SINGLE != 'undefined' && SINGLE) {

        var slider = false,
            mySwiper = undefined;

        if ( $('.swiper-container').length > 0 ) {

            resizePackageScreen();

        }

        $( window ).resize(function() {

            if ( $('.swiper-container').length > 0 ) {

                resizePackageScreen();

            }

        });

    }

    function resizePackageScreen() {

        var screenWidth = $( window ).width();

        var space_left = 20;
        if (screenWidth >= 768 && screenWidth <= 1024) {

            space_left = 30;
        }

        if (screenWidth <= 648 && slider != 2) {

            if (slider == 1) {

                destroyPackageSwiper();

            }

            fullPackageScreen(true);

            mySwiper = new Swiper('.swiper-container', {
                pagination: '.swiper-pagination',
                slidesPerView: 1.3,
                paginationClickable: true,
                slidesOffsetBefore: space_left,
                slidesOffsetAfter: space_left,
                spaceBetween: space_left
            });

            slider = 2;

        } else if (screenWidth > 648 && screenWidth <= 924 && slider != 1) {

            if (slider == 2) {

                destroyPackageSwiper();

            }

            fullPackageScreen(true);

            mySwiper = new Swiper('.swiper-container', {
                pagination: '.swiper-pagination',
                slidesPerView: 2.3,
                paginationClickable: true,
                slidesOffsetBefore: space_left,
                slidesOffsetAfter: space_left,
                spaceBetween: space_left
            });

            slider = 1;

        } else if (screenWidth > 924 && slider) {

            destroyPackageSwiper();

            fullPackageScreen(false);

            slider = false;

        }

    }

    function destroyPackageSwiper() {

        $('.swiper-container').each(function() {

            var swiper = $(this)[0].swiper;

            swiper.destroy(true, true);

        });

    }

    function fullPackageScreen(bool) {

        if (bool) {

            $('.swiper-container').closest('.wrapper.partials')
                .css('padding-right', 0)
                .css('padding-left', 0);

            $('.campaign-description, .campaign-last-donations').css('padding', '20px');

        } else {

            $('.swiper-container').closest('.wrapper.partials')
                .css('padding-right', '20px')
                .css('padding-left', '20px');

            $('.campaign-description').css('padding', '0 40px 0 0');
            $('.campaign-last-donations').css('padding', '0 0 0 40px');

        }

    }

    if(typeof SINGLE != 'undefined' && SINGLE) {

        $('.tab').click(function(event) {

            if (!$(this).hasClass('active')) {

                event.preventDefault();

                var href = $(this).data('href'),
                    _fade = $('.fade-container'),
                    height = _fade.height(),
                    url = PHP_TABS + '/' + href;

                if( $('.hidden_member').length > 0 ) {

                    url = url + '?via='+ $('.hidden_member').data('id');
                }

                $('.tab').removeClass('active');
                $(this).addClass('active');

                _fade.css('height', height).css('opacity', 0);

                setTimeout(function() {

                    $.ajax({
                            url: url
                        })
                        .done(function( data ) {

                            _fade.empty().html( data ).css('height', 'auto');

                            if ( $('.swiper-container').length > 0 ) {

                                var screenWidth = $( window ).width();

                                slider = false;

                                resizePackageScreen();

                            } else {

                                $('.wrapper.partials').removeAttr('style');

                            }

                            setTimeout(function() {

                                height = 0;
                                $('.fade-container').children().each(function() {

                                    height += $(this).height();
                                });

                                //_fade.css('height', height).css('opacity', 1);
                                _fade.css('opacity', 1);
                            }, 200);

                        });
                }, 200);



            }


        });

    }

});