
$( function() {

    $( '.display_search').click( function( event ) {

        event.preventDefault();

        if ( $( '#search').css('display') == 'block' ) {

            $( '#search' ).fadeOut(400);

            $( 'body').css('overflow', 'auto');
        } else {

            $( '#search' ).fadeIn(400);

            $( 'body').css('overflow', 'hidden');

            $( '#search_text' ).focus();
        }

    });

    $( '#search .delete-search').click( function() {

        $( '#search' ).fadeOut(400);

        $( 'body').css('overflow', 'auto');

    });

    $( '#open-mobile-nav').click(function() {

        $( '#search' ).fadeOut(400);

        $( 'body').css('overflow', 'auto');

    });

});