
$(function() {

    if ( $( '#loginForm').length > 0 ) {

        $( '#linktoLoginForm').click(function() {

            $( '#registerForm').css('display', 'none');
            $( '#loginForm').css('display', 'block');

        });

        $( '#linktoRegisterForm').click(function() {

            $( '#registerForm').css('display', 'block');
            $( '#loginForm').css('display', 'none');

        });

        if ( $( '#registerForm .error-message').length > 0 ) {

            $( '#linktoRegisterForm').click();

        }

    }

});