
$(function() {

    if ( $('.free-shipping').length > 0 && $('.details-packages').length && $(window).width() > 767 ) {

        var freeShipping = $('.free-shipping'),
            begin_show = $('.details-packages').offset().top;

        $(window).scroll(function (event) {
            var scroll = $(window).scrollTop() + $(window).height();

            if ( scroll > begin_show && !freeShipping.hasClass('opened') && !freeShipping.hasClass('closed')) {

                freeShipping.addClass('opened').fadeIn(200);

            } else if ( scroll < begin_show && freeShipping.hasClass('opened') ) {

                freeShipping.removeClass('opened').fadeOut(200);

            }

        });

        freeShipping.find('.close').click(function() {

            freeShipping.removeClass('opened').addClass('closed').fadeOut(200);

        });

    }

});