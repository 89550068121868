
$(function() {

  if ( $('#allCampaignsChangeAuthor').length > 0 ) {

    $('#allCampaignsChangeAuthor').change(function() {

        var value = $(this).val(),
          url = AJAX_URL + '/' + value,
          _wrapper = $('.campaigns');

        _wrapper.fadeOut(100);

      $.ajax({
        url: url
      })
        .done(function( data ) {

          setTimeout(function() {

              _wrapper.empty().html( data ).fadeIn();
          }, 200);

        });
    });
  }

});