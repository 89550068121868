
// Namespacing

window.Bean = window.Bean  || {};


// DataForm Module

(function ($) {

    "use strict";

    var self,
        activateButtons,
        saveButtons,
        resetButtons,
        cache;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function DataForm() {

        self = this;

        activateButtons = $('.active-input-button');
        saveButtons = $('.save-input-button');
        resetButtons = $('.reset-input-button');
        cache = [];
    }

    // ------------------------------------
    // METHODS
    // ------------------------------------

    DataForm.prototype.init = function () {
        
        this.activateHandler();
        this.desactivateHandler();
        this.saveHandler();
    };


    DataForm.prototype.activate = function (button) {

        self = this;

        var container = $(button).parent().parent();
        var inputs = $(container).find('input, select');

        // Process every inputs

        $.each( inputs, function( key, element ) {

            // Save data in field before activation

            var name = $(element).attr('name');
            var value = $(element).val();

            cache[name] = value; 

            $(container).removeClass('disabled').trigger('classChange');

            $(element).prop('disabled', false);

            inputs.first().focus();
        });
    };


    DataForm.prototype.desactivate = function (button, withCache) {

        self = this;

        var container = $(button).parent().parent();
        var inputs = $(container).find('input, select');

        // Process every inputs

        $.each( inputs, function( key, element ) {

            $(container).addClass('disabled').trigger('classChange');

            if( withCache ) {

                $(element).val( cache[$(element).attr('name')] );
            }

            $(element).prop('disabled', true);
        });
    };


    DataForm.prototype.save = function (button) {

        self = this;

        var container = $(button).parent().parent(),
            inputs = $(container).find('input, select'),
            data = {};


        // Process every inputs

        $.each( inputs, function( key, element ) {

            // Save data in field before activation

            var nameField = $(element).attr('name');
            var valueField = $(element).val();

            data[nameField] = valueField;

        });


        // Clear errors

        $(inputs).removeClass('error');


        // Update server side

        $.ajax({
            type: 'POST',
            url: $(button).closest('form').attr('action'),
            data: data,
            dataType: "json",
            success: function(data, status) {

                self.desactivate( $(button), false );
            },
            error: function(data, status) {

                if( data.responseJSON.code == 401 ) {

                    for( var name in data.responseJSON.errors ) {

                        $('input[name='+ name +']').addClass('error ' + data.responseJSON.errors[name]);
                    }
     
                }
            }
        });
    };




    // ------------------------------------
    // HANDLERS
    // ------------------------------------

    DataForm.prototype.activateHandler = function () {

        self = this;

        activateButtons.click( function(e) {

            self.activate( $(this) );

            e.preventDefault();
            return false;

        });
    };


    DataForm.prototype.desactivateHandler = function () {

        self = this;

        resetButtons.click( function(e) {

            self.desactivate( $(this), true );

            e.preventDefault();
            return false;

        });
    };


    DataForm.prototype.saveHandler = function () {

        self = this;

        saveButtons.click( function(e) {

            self.save( $(this) );

            e.preventDefault();
            return false;

        });
    };


    this.dataForm = new DataForm();


}.bind(Bean, jQuery)());