
$(function() {

    if ( $( '#loginOrder' ).length > 0 ) {

        $( 'input[name=auth]' ).change(function() {

            if ( $( 'input[name=auth]:checked' ).val() == 'login' ) {

                $( '#loginForm' ).css('display', 'block');
                $( '#registerForm' ).css('display', 'none');
            } else {

                $( '#loginForm' ).css('display', 'none');
                $( '#registerForm' ).css('display', 'block');
            }

        }).change();

    }

});