
// Namespacing

window.Bean = window.Bean  || {};


// Validation Module

(function ($) {

    "use strict";

    var self,
        $campaignNameField,
        $campaignNameMax;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function Validation() {

        self = this;
        this.$campaignNameField = $('#campaign-name-field');
        this.$campaignNameMax = $('#campaign-name-max');
    }

    // ------------------------------------
    // METHODS
    // ------------------------------------

    Validation.prototype.init = function () {

        this.validateCampaignNameLength();
        this.validateCampaignNameLengthHandler();
    };


    Validation.prototype.validateCampaignNameLength = function () {

        self = this;

		var text_max = 60,
            $campaignNameField = $('#campaign-name-field'),
            $campaignNameMax = $('#campaign-name-max');

        if( $campaignNameField.length && $campaignNameMax.length ) {

            var text_length = $campaignNameField.val().length;
            var text_remaining = text_max - text_length;

            $campaignNameMax.html(text_length);

            if( text_length >= 50 ) {

                $campaignNameMax.parent().addClass('error');
                $campaignNameMax.parent().removeClass('warning');
            }
            else if( text_length >= 40 ) {

                $campaignNameMax.parent().addClass('warning');
                $campaignNameMax.parent().removeClass('error');
            }
            else {

                $campaignNameMax.parent().removeClass('warning error');
            }
        }

    };



    // ------------------------------------
    // HANDLERS
    // ------------------------------------

    Validation.prototype.validateCampaignNameLengthHandler = function () {

        self = this;

        self.$campaignNameField.keyup( self.validateCampaignNameLength );
    };



    this.validation = new Validation();


}.bind(Bean, jQuery)());

