
$(function() {

    $( '#checkbox_billing').change( function() {

        var _billing = $( '#billing' );

        if ( $( this ).is( ':checked' ) ) {

            _billing.slideDown(200);
        } else {

            _billing.slideUp(200)
        }

    }).change();

});