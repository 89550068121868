
$( function() {

    if ( $( '#delete_campaign').length > 0 ) {

        $( '#delete_campaign').click( function() {

            $( '#delete_block').fadeIn(400);

            $("html, body").animate({ scrollTop: $(document).height() }, 400);

        });

        $( '#delete_cancel').click( function() {

            $( '#delete_block').fadeOut(400);

            $("html, body").animate({ scrollTop: $(document).height() }, 400);

        });

    }

});